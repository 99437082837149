
import { Component, Prop, Vue } from 'vue-property-decorator';

import CheckboxInput from '@/components/shared/CheckboxInput.vue';

@Component({
  name: 'PrivacyTerms',
  components: {
    CheckboxInput,
  },
})
export default class PrivacyTerms extends Vue {
  @Prop({ default: false }) checked: boolean;
  @Prop({}) urlTerms!: string;
  @Prop({ default: 'Termos' }) placeholderTerms!: string;
  @Prop({ default: false }) disabled: boolean;

  private agreedWithTerms = false;

  get agreed(): boolean {
    return this.agreedWithTerms || this.checked;
  }

  set agreed(agreed: boolean) {
    this.agreedWithTerms = agreed;
  }

  veriftyInputStatus(status: boolean) {
    this.$emit('change', status);
  }
}
