
import { Component, Model, Vue } from 'vue-property-decorator';

import PictureInput from '@/components/shared/PictureInput.vue';

@Component({
  name: 'ProfilePicture',
  components: {
    PictureInput,
  },
})
export default class ProfilePicture extends Vue {
  public noProfilePhoto = require('@/assets/img/no-profile-photo.png');

  @Model('change', { type: [String, File] }) photo!: string | File;

  public changePhoto(photo: File) {
    this.$emit('change', photo);
  }

  get userPhoto(): string | File {
    return this.photo && typeof this.photo === 'string' ? this.photo : this.noProfilePhoto;
  }
}
