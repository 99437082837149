<template>
  <picture-input
    radius="50"
    class="picture-input"
    ref="pictureInput"
    @change="onChange"
    :prefill="preSelected"
    width="157"
    height="157"
    accept="image/jpeg,image/png,image/jpg,image/gif"
    size="25"
    :customStrings="{
      upload: 'Seu dispostivo não suporta upload de arquivo.',
      drag: '',
      tap: 'Toque aqui para selecionar uma foto de sua galeria',
      change: 'Alterar foto',
      aspect: 'Landscape/Portrait',
      remove: 'Remover foto',
      select: 'Selecionar uma foto',
      selected: 'Foto selecionada com sucesso',
      fileSize: 'O tamanho do arquivo excede o limite',
      fileType: 'Este tipo de arquivo não é suportado.',
    }"
  />
</template>

<script>
import PictureInput from 'vue-picture-input';

export default {
  name: 'PictureInputComponent',
  data() {
    return {};
  },
  props: {
    preSelected: {
      default: '',
      type: [String, File],
    },
  },
  components: {
    PictureInput,
  },
  methods: {
    onChange(image) {
      if (image) {
        this.$emit('change', this.$refs.pictureInput.file);
      }
    },
  },
};
</script>

<style lang="sass">
.picture-input
  border-radius: 50px

  button
    display: none

.picture-inner
  border: none !important
</style>
