var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('form',{staticClass:"profile-form"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.profile[_vm.ProfileFormModelKey.name].value),expression:"profile[ProfileFormModelKey.name].value"}],staticClass:"profile-form__name",class:{
      'profile-form--required':
        _vm.profile[_vm.ProfileFormModelKey.name].notFilled &&
        _vm.profile[_vm.ProfileFormModelKey.name].required,
    },attrs:{"placeholder":"Nome","type":"text","name":"name","disabled":"","required":_vm.profile[_vm.ProfileFormModelKey.name].required},domProps:{"value":(_vm.profile[_vm.ProfileFormModelKey.name].value)},on:{"blur":function($event){return _vm.validate(null, _vm.ProfileFormModelKey.name)},"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.profile[_vm.ProfileFormModelKey.name], "value", $event.target.value)},_vm.validate]}}),_c('div',{staticClass:"profile-form--required-text"},[(
        _vm.profile[_vm.ProfileFormModelKey.name].notFilled &&
        _vm.profile[_vm.ProfileFormModelKey.name].required
      )?_c('span',[_vm._v(" Campo Obrigatório ")]):_vm._e()]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.profile[_vm.ProfileFormModelKey.nick].value),expression:"profile[ProfileFormModelKey.nick].value"}],staticClass:"profile-form__nick",class:{
      'profile-form--required':
        _vm.profile[_vm.ProfileFormModelKey.nick].notFilled &&
        _vm.profile[_vm.ProfileFormModelKey.nick].required,
    },attrs:{"placeholder":"Como você gostaria de ser chamado(a)","type":"text","name":"nick","required":_vm.profile[_vm.ProfileFormModelKey.nick].required},domProps:{"value":(_vm.profile[_vm.ProfileFormModelKey.nick].value)},on:{"blur":function($event){return _vm.validate(null, _vm.ProfileFormModelKey.nick)},"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.profile[_vm.ProfileFormModelKey.nick], "value", $event.target.value)},_vm.validate]}}),_c('div',{staticClass:"profile-form--required-text"},[(
        _vm.profile[_vm.ProfileFormModelKey.nick].notFilled &&
        _vm.profile[_vm.ProfileFormModelKey.nick].required
      )?_c('span',[_vm._v(" Campo Obrigatório ")]):_vm._e()]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.profile.work.value),expression:"profile.work.value"}],staticClass:"profile-form__work",class:{
      'profile-form--required':
        _vm.profile[_vm.ProfileFormModelKey.work].notFilled &&
        _vm.profile[_vm.ProfileFormModelKey.work].required,
    },attrs:{"placeholder":"Empresa/Profissão","type":"text","name":"work","required":_vm.profile[_vm.ProfileFormModelKey.work].required},domProps:{"value":(_vm.profile.work.value)},on:{"blur":function($event){return _vm.validate(null, _vm.ProfileFormModelKey.work)},"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.profile.work, "value", $event.target.value)},_vm.validate]}}),_c('div',{staticClass:"profile-form--required-text"},[(
        _vm.profile[_vm.ProfileFormModelKey.work].notFilled &&
        _vm.profile[_vm.ProfileFormModelKey.work].required
      )?_c('span',[_vm._v(" Campo Obrigatório ")]):_vm._e()]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.profile[_vm.ProfileFormModelKey.linkedin].value),expression:"profile[ProfileFormModelKey.linkedin].value"}],staticClass:"profile-form__linkedin",class:{
      'profile-form--required':
        _vm.profile[_vm.ProfileFormModelKey.linkedin].notFilled &&
        _vm.profile[_vm.ProfileFormModelKey.linkedin].required,
    },attrs:{"placeholder":"Link do Linkedin","type":"text","name":"linkedin","required":_vm.profile[_vm.ProfileFormModelKey.linkedin].required},domProps:{"value":(_vm.profile[_vm.ProfileFormModelKey.linkedin].value)},on:{"blur":function($event){return _vm.validate(null, _vm.ProfileFormModelKey.linkedin)},"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.profile[_vm.ProfileFormModelKey.linkedin], "value", $event.target.value)},_vm.validate]}}),_c('div',{staticClass:"profile-form--required-text"},[(
        _vm.profile[_vm.ProfileFormModelKey.linkedin].notFilled &&
        _vm.profile[_vm.ProfileFormModelKey.linkedin].required
      )?_c('span',[_vm._v(" Campo Obrigatório ")]):_vm._e()]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.profile[_vm.ProfileFormModelKey.biography].value),expression:"profile[ProfileFormModelKey.biography].value"}],staticClass:"profile-form__biography",class:{
      'profile-form--required':
        _vm.profile[_vm.ProfileFormModelKey.biography].notFilled &&
        _vm.profile[_vm.ProfileFormModelKey.biography].required,
    },attrs:{"placeholder":"Biografia","required":_vm.profile[_vm.ProfileFormModelKey.biography].required},domProps:{"value":(_vm.profile[_vm.ProfileFormModelKey.biography].value)},on:{"blur":function($event){return _vm.validate(null, _vm.ProfileFormModelKey.biography)},"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.profile[_vm.ProfileFormModelKey.biography], "value", $event.target.value)},_vm.validate]}}),_vm._v(" "),_c('div',{staticClass:"profile-form--required-text"},[(
        _vm.profile[_vm.ProfileFormModelKey.biography].notFilled &&
        _vm.profile[_vm.ProfileFormModelKey.biography].required
      )?_c('span',[_vm._v(" Campo Obrigatório ")]):_vm._e()]),_c('PrivacyTerms',{staticClass:"profile-form__privacy-terms",attrs:{"disabled":!_vm.terms,"checked":_vm.profile[_vm.ProfileFormModelKey.terms].value},on:{"change":_vm.handleTerms}}),_c('ButtonDefault',{staticClass:"profile-form__save",attrs:{"padding":"15px 30px","disabled":_vm.saveDisabled,"preenchido":true,"loading":_vm.loading,"msg":"SALVAR"},on:{"click":_vm.save}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }