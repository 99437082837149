
import {
  Component,
  Vue,
  Model,
  Prop,
} from 'vue-property-decorator';
import { mapGetters } from 'vuex';

import PrivacyTerms from '@/components/shared/PrivacyTerms.vue';
import ButtonDefault from '@/components/shared/ButtonDefault.vue';

import { BuscaTermosPrivacidade } from '@/models/api/busca-termos-privacidade';
import {
  ProfileFormModel,
  ProfileFormModelKey,
} from '@/models';

import { PrivacyTermsService } from '@/services';

@Component({
  name: 'ProfileForm',
  computed: {
    ...mapGetters('privacyTerms', {
      terms: 'terms',
    }),
  },
  components: {
    ButtonDefault,
    PrivacyTerms,
  },
})
export default class ProfileForm extends Vue {
  public terms!: BuscaTermosPrivacidade;

  @Prop({ default: false }) public loading!: boolean;
  @Model('change', { type: ProfileFormModel }) profile!: ProfileFormModel;

  public saveDisabled = true;
  public ProfileFormModelKey = ProfileFormModelKey;

  public handleTerms(checked: boolean) {
    if (!this.profile.terms.value) this.openModal();
    this.profile.terms.value = checked;
    this.validate();
  }

  public openModal() {
    PrivacyTermsService.openModal(this.terms, true, true);
  }

  public validate(
    event?: InputEvent | FocusEvent,
    profileKey?: ProfileFormModelKey,
  ) {
    const requireds: string[] = [];

    if (profileKey) {
      this.profile[profileKey].notFilled = Boolean(
        !this.profile[profileKey].value,
      );
    }

    const filled = Object.entries(this.profile).filter(
      (i: [string, {
        value: string | boolean;
        required: boolean;
      }]) => {
        const [key, content] = i;
        if (content.required) requireds.push(key);
        return content.required && Boolean(content.value);
      },
    );

    this.saveDisabled = requireds.length !== filled.length;
  }

  public save(e: Event) {
    e.preventDefault();
    this.$emit('onSave', this.profile);
  }
}
