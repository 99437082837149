
import { Component, Vue } from 'vue-property-decorator';
import { mapActions, mapGetters } from 'vuex';
import Header from '@/components/Header/Header.vue';
import ProfileForm from '@/components/ProfilePage/ProfileForm.vue';
import ProfilePicture from '@/components/ProfilePage/ProfilePicture.vue';
import Footer from '@/components/Footer/Footer.vue';
import { RouteName } from '@/models/route-name';

import { ProfileFormModel, Course, UserModel } from '@/models';

const { PENDING_ASSIGNMENTS, ASSIGNMENT_OBJECT_MANAGE, COURSES } = RouteName;

@Component({
  name: 'ProfilePage',
  components: {
    Header,
    ProfilePicture,
    ProfileForm,
    Footer,
  },
  computed: {
    ...mapGetters('user', {
      isStudent: 'isStudent',
      getUserCourses: 'getUserCourses',
      loading: 'updating',
      userInfos: 'userInfos',
      userPhoto: 'userPhoto',
    }),
  },
  methods: {
    ...mapActions('user', {
      updateUserInfo: 'updateUserInfo',
      postUserInfo: 'postUserInfo',
    }),
  },
})
export default class ProfilePage extends Vue {
  public postUserInfo!: ([profile, photo]: [
    ProfileFormModel,
    File | null,
  ]) => Promise<UserModel>;
  private isStudent!: boolean;
  private getUserCourses!: Course[];
  private userInfos!: UserModel;
  private userPhoto!: string;

  public photo = '';
  public toUpload: File | null = null;
  public profile = new ProfileFormModel();

  created() {
    this.setData();
  }

  private setData() {
    this.photo = this.userPhoto;
    this.profile = new ProfileFormModel(this.userInfos);
  }

  public saveProfile() {
    const routeAfterSave = this.checkRoute();

    this.postUserInfo([this.profile, this.toUpload]).then(() => {
      this.$router.push({ name: routeAfterSave });
    });
  }

  private checkRoute(): RouteName {
    if (this.isStudent) {
      return this.getUserCourses.length > 1 ? COURSES : PENDING_ASSIGNMENTS;
    }

    return ASSIGNMENT_OBJECT_MANAGE;
  }

  public changePhoto(photo: File) {
    this.toUpload = photo;
  }
}
