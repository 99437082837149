var render = function render(){var _vm=this,_c=_vm._self._c;return _c('picture-input',{ref:"pictureInput",staticClass:"picture-input",attrs:{"radius":"50","prefill":_vm.preSelected,"width":"157","height":"157","accept":"image/jpeg,image/png,image/jpg,image/gif","size":"25","customStrings":{
    upload: 'Seu dispostivo não suporta upload de arquivo.',
    drag: '',
    tap: 'Toque aqui para selecionar uma foto de sua galeria',
    change: 'Alterar foto',
    aspect: 'Landscape/Portrait',
    remove: 'Remover foto',
    select: 'Selecionar uma foto',
    selected: 'Foto selecionada com sucesso',
    fileSize: 'O tamanho do arquivo excede o limite',
    fileType: 'Este tipo de arquivo não é suportado.',
  }},on:{"change":_vm.onChange}})
}
var staticRenderFns = []

export { render, staticRenderFns }